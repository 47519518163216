import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import WeddingService, { CreateWeddingMessageRequest } from '../../api/wedding';
import Button from '../../components/Button/Button';
import { WEDDING_ID } from '../../config';
import { RoutePath } from '../../models/route';
import style from './style.css';

const Message: FunctionalComponent = () => {
  const [message, setMessage] = useState<string>('')
  const [isCreatingMessage, setIsCreatingMessage] = useState<boolean>(false)

  const onSuccess = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const encodedWedding: string = urlParams.get("wedding")!;
    route(`${RoutePath.Results}?wedding=${encodedWedding}`, true)
  }

  const onError = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const encodedWedding: string = urlParams.get("wedding")!;
    route(`${RoutePath.Results}?wedding=${encodedWedding}`, true)
  }

  const handleSubmit = (): void => {
    setIsCreatingMessage(true)

    const payload: CreateWeddingMessageRequest = {
      weddingId: WEDDING_ID,
      message,
    }

    WeddingService().createWeddingMessage(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(() => {
        setIsCreatingMessage(false)
      })
  }

  return (
    <div class={style.viewWrapper}>
      <div class={style.headerWrapper}>
        <h3>Suggestion box for our relationship</h3>
      </div>
      <textarea
        rows={3}
        class={style.textarea}
        value={message}
        onInput={(e) =>
          setMessage((e.target as HTMLInputElement).value)
        }
      />
      <div class={style.actionsWrapper}>
        <Button loading={isCreatingMessage} onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  );
};

export default Message;
